import React from "react";

export default function EmailIcon() {
    const style = {
        width: "24",
        height: "24",
        fill: 'rgba(255, 255, 255, 1)',
        transform: '',
        msFilter:'',

    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" style={style} viewBox="0 0 24 24">
            <path d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 4.7-8 5.334L4 8.7V6.297l8 5.333 8-5.333V8.7z">
            </path>
        </svg>
    )
}
