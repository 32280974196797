import React from 'react'
 export default function LinkedIn() {
 return (
 <>
 <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" stroke="#414535" fill="none" d="M19.34 22H4.66C3.19 22 2 20.81 2 19.34V4.66C2 3.19 3.19 2 4.66 2H19.33C20.81 2 22 3.19 22 4.66V19.33C22 20.81 20.81 22 19.34 22Z"></path>
<path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" stroke="#414535" d="M7 10V17"></path>
<path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" stroke="#414535" d="M17 17C17 17 17 12.45 17 12.44C17 11.87 17.03 11.21 16.76 10.69C16.16 9.51 14.57 10.11 13.72 10.6C12.84 11.1 12.04 11.74 11.28 12.4C11.19 12.48 11.09 12.57 11 12.65V10.01V17"></path>
<path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" stroke="#414535" fill="none" d="M7 7C7.27614 7 7.5 6.77614 7.5 6.5C7.5 6.22386 7.27614 6 7 6C6.72386 6 6.5 6.22386 6.5 6.5C6.5 6.77614 6.72386 7 7 7Z"></path>
</svg>
</>
)}